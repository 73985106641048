<template>
  <div class="proposal-slider">
    <ebsn-meta
      @click="clicked"
      :target="config"
      path="categoryblocktype_OffertaSlider.DESCRIPTION"
      tag="div"
    />
    <ebsn-style
      :target="category"
      path="categoryblocktype_OffertaSlider.CSS_STYLE"
    />
    <swiper :options="swiperOption" ref="swiperRef" v-if="proposals != null">
      <swiper-slide v-for="proposal in proposals" :key="proposal.id">
        <v-card
          rounded="md"
          :to="{ name: 'Flyer', params: { volantino: proposal.slug } }"
          elevation="2"
          class="proposal-img"
        >
          <v-img
            v-if="proposal && proposal.featured_image_url"
            :src="proposal.featured_image_url"
            :alt="proposal.title?.rendered || 'Offerta'"
            class="offerta-image align-center"
          >
            <v-icon x-large class="magnify" color="white"
              >mdi-magnify-plus-outline</v-icon
            >
          </v-img>
        </v-card>
      </swiper-slide>
    </swiper>
    <div
      class="swiper-pagination"
      :class="paginationClass"
      slot="pagination"
    ></div>
  </div>
</template>
<style lang="scss">
.swiper-pagination-offerta {
  position: initial;
  text-align: center;
}
.v-image.offerta-image {
  .v-response__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .magnify {
    width: 100%;
  }
}
</style>
<script>
import CmService from "~/service/cmService.js";
import clickHandler from "~/mixins/clickHandler";
import categoryBlockType from "@/components/categoryBlock/categoryBlockType";
import category from "~/mixins/category";

export default {
  name: "OffertaSlider",
  props: {
    config: { type: Object, required: true },
    title: { type: String, required: false },
    paginationClass: { type: String, default: "swiper-pagination-offer" }
  },
  mixins: [clickHandler, categoryBlockType, category],
  data() {
    return {
      proposals: null,
      swiperOption: {
        slidesPerView: this.slidesPerView,
        spaceBetween: 20,
        autoplay: true,
        watchOverflow: true,
        pagination: {
          el: "." + this.paginationClass,
          clickable: true
        },
        breakpoints: {
          320: {
            slidesPerView: 1.5,
            centeredSlides: true
          },
          600: {
            slidesPerView: 1.5,
            slidesPerGroup: 1.5,
            centeredSlides: true
          },
          960: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            centeredSlides: false
          },
          1280: {
            slidesPerView: 4,
            slidesPerGroup: 3,
            centeredSlides: false
          },
          1590: {
            slidesPerView: 5,
            slidesPerGroup: 5,
            centeredSlides: false
          }
        }
      }
    };
  },
  async mounted() {
    let res = await CmService.getCustomPostByFilters("offerta");
    if (res && res.data && res.data?.length > 0) {
      this.proposals = res.data;
    }
  }
};
</script>
