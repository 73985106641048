<template functional>
  <div class="d-flex flex-grow-1 qty-wrap-cnt">
    <div
      class="qty-wrap py-1 mr-1 rounded-sm"
      :class="{
        'not-empty': parent.quantity > 0,
        'flex-grow-1':
          !props.isProductDetail || parent.$vuetify.breakpoint.smAndDown,
        'w-48': props.isProductDetail && parent.$vuetify.breakpoint.mdAndUp
      }"
      @mousedown.stop
    >
      <!-- <v-btn
      v-if="enableAcqBox && product.logisticPackagingItems && quantity <= 0"
      :aria-label="
        `Aggiungi un cartone da ${product.logisticPackagingItems} al carrello`
      "
      color="primary"
      outlined
      height="45"
      class="btn-add-box mr-1"
      small
      @click.stop.prevent="
        addToCart(product.logisticPackagingItems, undefined, {
          searchUid: product.searchUid,
          position: position
        })
      "
    >
      <div>
        {{ product.logisticPackagingItems }}
        <v-icon color="primary" small>$pack</v-icon>
      </div>
      <div class="text-uppercase small">
        pezzi
      </div>
    </v-btn> -->

      <v-btn
        aria-label="Diminuisci quantità"
        depressed
        color="transparent"
        small
        class="minus px-1"
        @click.stop.prevent="
          parent.minus(
            props.updateCart,
            { infos: { ...props.selectedOptions } },
            {
              searchUid: props.product.searchUid,
              position: props.position
            }
          )
        "
      >
        <v-icon color="default" small>$minus</v-icon>
      </v-btn>
      <div class="val-cnt">
        <span class="val">{{ parent.quantity }}{{ parent.unit }}</span>
        <span class="small">{{ parent.quantityPerUnit }}</span>
      </div>
      <v-btn
        aria-label="Aumenta quantità"
        class="plus px-1"
        small
        depressed
        color="transparent"
        @click.stop.prevent="
          parent.plus(
            props.updateCart,
            {
              infos: {
                ...props.selectedOptions
              }
            },
            {
              searchUid: props.product.searchUid,
              position: props.position
            }
          )
        "
      >
        <!-- v-ripple -->
        <v-icon color="default" small>$plus</v-icon>
        <!-- <template>
          <v-icon>$cart</v-icon>
          <span
            v-if="label"
            class="font-weight-bold white--text text-uppercase"
          >
            {{ label }}
          </span>
        </template> -->
      </v-btn>
    </div>
    <v-tooltip top v-if="!props.isAward">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          v-if="
            props.showCartButton &&
              props.product.selectOptions &&
              props.product.selectOptions.length
          "
          depressed
          class="rounded-sm select-options-btn mr-1"
          color="primary"
          @click="
            parent.openOptionsModal(
              props.product.selectOptions,
              parent.$t('common.confirm')
            )
          "
        >
          <v-icon>$menu</v-icon>
        </v-btn>
      </template>
      <span>Modifica le opzioni</span>
    </v-tooltip>
    <v-tooltip top v-if="!props.isAward">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          v-if="
            props.showCartButton &&
              props.product.variations &&
              props.product.variations.length
          "
          depressed
          class="rounded-sm select-options-btn mr-1"
          color="primary"
          @click="parent.openVariationsModal(parent.$t('common.confirm'))"
        >
          <v-icon>$menu</v-icon>
        </v-btn>
      </template>
      <span>Modifica le opzioni</span>
    </v-tooltip>

    <v-btn
      v-if="props.showCartButton"
      depressed
      class="rounded-sm add-to-cart"
      :color="props.inCart ? 'secondary' : 'primary'"
      @click.stop.prevent="
        parent.addToCart(parent.quantity, {
          infos: { ...props.selectedOptions }
        })
      "
    >
      <span class="text-uppercase pa-3" v-if="props.isAward">
        <span class="font-weight-medium">{{
          parent.$t("awards.booked.addToCart")
        }}</span
        ><br />
        <span>{{ parent.$t("awards.booked.premiCart") }}</span>
      </span>
      <v-icon v-else>$cart</v-icon>
      <transition name="bounce">
        <v-icon class="in-cart-icon" v-if="props.inCart">
          $complete
        </v-icon>
      </transition>
      <span
        v-if="props.showAddToCartLabel && parent.$vuetify.breakpoint.mdAndUp"
        class="ml-2"
        >{{ parent.$t("products.addToCart") }}</span
      >
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "ProductQty",
  props: {
    product: { type: Object, required: true },
    item: { type: Object, required: false },
    updateCart: { type: Boolean, default: global.config.updateCart },
    label: { type: String },
    selectedOptions: { type: Object },
    position: { type: Number, default: undefined },
    isProductDetail: { type: Boolean, default: false },
    isAward: { type: Boolean, default: false },
    isPromoCart: { type: Boolean, default: false },
    showCartButton: { type: Boolean, default: true },
    showAddToCartLabel: { type: Boolean, default: false },
    inCart: { type: Boolean, default: false }
  }
};
</script>
