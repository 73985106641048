<template>
  <main>
    <div class="autocomplete d-flex flex-row">
      <v-combobox
        class="search-input"
        clearable
        filled
        solo
        dense
        v-model="selected"
        ref="autocomplete"
        :items="getItems"
        :search-input.sync="search"
        no-filter
        :label="
          isListening
            ? $t('navbar.speechActive')
            : $t('navbar.searchPlaceholder')
        "
        :placeholder="
          isListening
            ? $t('navbar.speechActive')
            : $t('navbar.searchPlaceholder')
        "
        flat
        item-text="name"
        item-value="name"
        hide-details
        hide-no-data
        return-object
        type="search"
        @focus="onFocus"
        @blur="onBlur"
        @keydown.enter.native.prevent.stop="doSearch"
        :menu-props="{
          closeOnContentClick: true,
          contentClass: 'searches-menu'
        }"
      >
        <template v-slot:item="{ item }">
          <div class="d-flex" :class="item.type" style="width: 100% !important">
            <v-list-item-icon v-if="item.type == 'Ricerche'">
              <v-icon @click.prevent.stop="deleteWord(item)" small>
                $close
              </v-icon>
            </v-list-item-icon>
            <v-list-item-icon class="search-icon">
              <img v-if="item.type == 'Categorie'" :src="getImage(item)" />
              <v-icon v-else small>mdi-magnify</v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="doSearch(item)">
              <v-list-item-title>
                <text-highlight :queries="search ? search : '*'">
                  {{ item.name }}
                </text-highlight>
              </v-list-item-title>
              <v-list-item-subtitle v-if="item.type == 'Categorie'">
                Cerca <strong>{{ search }}</strong> in {{ item.name }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="item.type == 'Marche'">
                Cerca <strong>{{ search }}</strong> con marca {{ item.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <div class="badge" v-if="item.count">
                {{ item.count }}
              </div>
              <!-- <v-icon
                v-if="item.type == 'Ricerche'"
                @click.prevent.stop="search = item.name"
                small
              >
                mdi-arrow-top-left
              </v-icon> -->
            </v-list-item-action>
          </div>
        </template>
      </v-combobox>
      <v-btn
        v-if="$vuetify.breakpoint.xsOnly"
        @click="openFastSearchDialog"
        icon
        color="white"
      >
        <v-icon>$fastSearch</v-icon>
      </v-btn>
    </div>
    <div
      class="py-5 d-flex flex-row justify-center"
      v-if="$platform_is_cordova && $vuetify.breakpoint.xs"
    >
      <span class="barcode-desc">Scansiona il prodotto</span>
      <Barcode @input="doSearch" />
    </div>
  </main>
</template>
<style scoped lang="scss">
main,
.autocomplete {
  width: 100% !important;
  .search-input {
    margin-right: 10px;
  }
}

.search-item {
  min-height: 36px;
}

.search-icon {
  margin-right: 5px !important;
  img {
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }
}
.barcode-desc {
  color: #ffffff;
  padding-right: 8px;
  font-size: 18px;
  font-weight: bold;
  position: relative;
}

.search-input {
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  opacity: 1;
  font-size: 13px;
  .v-input__control {
    display: inline;
    height: 100%;
    .v-input__slot {
      height: 100%;
    }
    .v-select__slot {
      input::placeholder {
        font-size: 11px !important;
      }
    }
  }
  .v-label {
    font-size: 11px !important;
  }
}
.v-list-item.v-list-item--link {
  display: inline-block !important;
  width: 100% !important;
}
.searches-menu {
  max-height: 80vh !important;
  background-color: blue;
  &.v-list--dense {
    .v-list-item {
      min-height: 32px;
    }
  }
  .v-list-item {
    .v-list-item__content {
      padding: 2px 0;
      min-width: 24px;
    }
    .v-list-item__title {
      font-size: 1rem;
    }
    .link {
      .v-list-item__title {
        color: var(--v-primary-base);
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
  .v-subheader {
    font: normal normal bold 14px/44px Montserrat;
    white-space: nowrap;
    &::before {
      content: "";
      display: block;
      width: 30px;
      height: 2px;
      background: var(--v-primary-base);
      margin-right: 8px;
    }
    &::after {
      content: "";
      display: block;
      width: 80%;
      height: 2px;
      background: var(--v-primary-base);
      margin-left: 8px;
    }
  }
}
</style>
<script>
import ProductService from "~/service/productService";
import AnalyticsService from "~/service/analyticsService";
import Barcode from "./Barcode";
import FastSearchMenu from "./FastSearchMenu";

import { mapGetters, mapActions } from "vuex";

import debounce from "lodash/debounce";
import forEach from "lodash/forEach";

export default {
  components: {
    Barcode
  },
  props: {
    focusOnOpen: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: "navbar.searchPlaceholder"
    },
    appendIcon: {
      type: String,
      required: false,
      default: "$search"
    }
  },
  data() {
    return {
      loading: false,
      isFocused: null,
      selected: null,
      search: null,
      isListening: false,
      items: [],
      menuProps: {
        closeOnContentClick: true,
        openOnClick: true
      },
      speechOptions: {
        language: "it-IT",
        matches: "5",
        prompt: "", // Android only
        showPopup: true, // Android only
        showPartial: false
      }
    };
  },
  watch: {
    search(val, oldVal) {
      if (val !== oldVal && val && val.length > 3) {
        this.fetchSuggestion();
      }
    }
  },
  computed: {
    ...mapGetters({
      commonSearch: "wordsSearched/commonSearch"
    }),
    getItems() {
      return this.search ? this.items : this.commonSearch;
    }
  },
  methods: {
    ...mapActions({
      saveWord: "wordsSearched/saveWord",
      deleteWord: "wordsSearched/deleteWord"
    }),
    getImage(item) {
      return "/search-category/" + item.id + ".png";
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    fetchSuggestion: debounce(async function() {
      let vm = this;
      vm.loading = true;
      if (vm.search) {
        await ProductService.suggest(vm.search.trim()).then(function(response) {
          let newItems = [];
          let prevType = "none";

          forEach(response, function(value) {
            if (value.type != prevType) {
              newItems.push({ header: value.type });
              prevType = value.type;
            }
            newItems.push(value);
          });
          vm.items = newItems;
          vm.loading = false;
        });
      }
    }, 200),
    doSearch(item) {
      this.items = [];
      this.$emit("closeSearchModal", true);
      var currentQuery = {};
      if (item.type === "Suggerimenti") {
        //ho selezionato un nome
        currentQuery.q = item.name;
      } else if (item.type === "Categorie") {
        currentQuery.parent_category_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Ricerche") {
        currentQuery.q = item.name;
      } else if (item.type === "Marche") {
        currentQuery.parent_vendor_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Barcode") {
        currentQuery.barcode = item.name;
      } else {
        currentQuery.q = this.search;
        this.saveWord(currentQuery.q);
      }

      if (currentQuery.q || currentQuery.barcode) {
        AnalyticsService.search(this.search);
        this.$store.dispatch("category/resetFilters");
        this.$router.push({
          // path: "/search?q=:q&parent_category_id=:parent_category_id",
          path: "/search",
          name: "Search",
          query: currentQuery
        });

        this.$nextTick(() => {
          const autocomplete = this.$refs.autocomplete;
          autocomplete.blur();
          this.search = currentQuery.q;
        });
      }
    },
    openFastSearchDialog() {
      this.$emit("closeSearchModal", true);
      var defaultConfig = {
        toolbarColor: "secondary",
        fullscreen: true
      };
      let properties = {
        waitForResult: true,
        width: 750
      };
      var config = Object.assign({}, properties, defaultConfig);
      this.dialogInstance = this.$dialog.show(FastSearchMenu, config);
    }
  },
  beforeDestroy() {
    global.EventBus.$off("clearSearch");
    global.EventBus.$off("focusSearch");
  },
  mounted() {
    global.EventBus.$on("clearSearch", () => {
      this.search = null;
      this.selected = null;
    });
    global.EventBus.$on("focusSearch", () => {
      setTimeout(() => {
        this.$refs.autocomplete.focus();
      }, 200);
    });
    if (this.focusOnOpen) {
      this.$refs.autocomplete.focus();
    }
  }
};
</script>
