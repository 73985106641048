import axios from "~/plugins/axios";

export default {
  getList() {
    return axios
      .get("/ebsn/api/price-list-proposal/list")
      .then(data => data.data)
      .catch(error => error);
  },
  getDetail(priceListProposalId) {
    let params = {
      price_list_proposal_id: priceListProposalId
    };
    return axios
      .get("/ebsn/api/price-list-proposal/view", {
        params: params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  addProductPrice(productId, priceListProposalId, price, referencePriceId) {
    let params = {
      product_id: productId,
      price_list_proposal_id: priceListProposalId,
      price: price,
      reference_price_id: referencePriceId
    };
    return axios
      .post("/ebsn/api/price-list-proposal/add-product-price", undefined, {
        params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  deleteProductPrice(priceId, priceListProposalId) {
    // /ebsn/api/price-list-proposal/delete-product-price?price_id=&price_list_proposal_id=
    let params = {
      price_id: priceId,
      price_list_proposal_id: priceListProposalId
    };
    return axios
      .post("/ebsn/api/price-list-proposal/delete-product-price", undefined, {
        params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  insert(name, priceListId, replacedPriceListId, clonePices, fromDate, toDate) {
    let params = {
      name: name,
      price_list_id: priceListId,
      replaced_price_list_id: replacedPriceListId,
      clone_prices: clonePices,
      from_date: fromDate,
      to_date: toDate
    };
    return axios
      .get("/ebsn/api/price-list-proposal/insert", {
        params: params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  delete(priceListProposalId) {
    let params = {
      price_list_proposal_id: priceListProposalId
    };
    return axios
      .get("/ebsn/api/price-list-proposal/delete-proposal", {
        params: params
      })
      .then(data => data.data)
      .catch(error => error);
  },
  validateProposal(priceListProposalId) {
    let params = {
      price_list_proposal_id: priceListProposalId
    };
    return axios
      .get("/ebsn/api/price-list-proposal/validate-proposal", {
        params: params
      })
      .then(data => data.data)
      .catch(error => error);
  }
};
