<template>
  <v-list class="sso-user-list">
    <v-list-item>
      <strong>{{ isSsoUser.username }}</strong>
    </v-list-item>
    <v-divider class="divider"></v-divider>
    <!-- <v-list-item @click.stop="refresh()">
        <v-list-item-title>
          <v-icon class="mr-1">mdi-refresh</v-icon>Ricarica categoria
        </v-list-item-title>
      </v-list-item> -->
    <v-list-item :to="{ name: 'ListUser' }">
      <v-icon class="mr-1">mdi-account-switch</v-icon>
      {{ $t("ssouser.userList") }}
    </v-list-item>
    <v-list-item v-if="user" :to="{ name: 'PriceListList' }" class="d-flex">
      <v-list-item-title>
        <v-icon class="mr-1">mdi-playlist-star</v-icon
        >{{ $t("navbar.navigation.priceListList") }}</v-list-item-title
      >
    </v-list-item>
    <v-list-item
      v-if="user"
      :to="{ name: 'PriceListProposalList' }"
      class="d-flex"
    >
      <v-list-item-title>
        <v-icon class="mr-1">mdi-playlist-edit</v-icon
        >{{ $t("navbar.navigation.priceListListProposal") }}</v-list-item-title
      >
    </v-list-item>
    <v-list-item @click.stop="ssoLogout()">
      <v-icon class="mr-1">mdi-logout</v-icon>
      <v-list-item-title v-text="'Esci'"></v-list-item-title>
    </v-list-item>
  </v-list>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import split from "lodash/split";

export default {
  name: "SsoUserdCard",
  data() {
    return {
      userList: null
    };
  },
  computed: {
    ...mapGetters({
      isSsoUser: "cart/isSsoUser",
      user: "cart/getUser"
    }),
    ...mapState({
      category: ({ category }) => category.category
    })
  },
  methods: {
    ...mapActions({
      doSsoLogout: "cart/doSsoLogout",
      setCategory: "category/setCategory",
      resetFilters: "category/resetFilters"
    }),
    async ssoLogout() {
      const logoutResult = await this.doSsoLogout();
      if (logoutResult) {
        this.$router.push({ name: "Home" });
      }
    },
    async refresh() {
      let slug = split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });
      //await this.$router.go();
    }
  }
};
</script>
