<template>
  <div
    v-if="!isAuthenticated"
    class="be-customer-card-horizonal d-flex justify-center align-center flex-column"
    :style="
      'background-image: url(' +
        proposal.metaData.category_proposal_type.IMAGE_DESKTOP +
        ')'
    "
  >
    <!-- <h2 :class="{ 'primary--text text-uppercase': card == 'registration' }"> -->
    <h2>
      {{ proposal.metaData.category_proposal_type.TITLE }}
    </h2>
    <h4 class="text-center px-4">
      {{ proposal.metaData.category_proposal_type.SUBTITLE }}
    </h4>
    <v-btn
      elevation="0"
      color="primary"
      class="mt-5"
      :to="{
        name: 'RegistrationUser'
      }"
      >{{ proposal.metaData.category_proposal_type.BTN_TEXT }}</v-btn
    >
  </div>
</template>
<style scoped lang="scss">
.be-customer-card-horizonal {
  background-size: cover;
  height: 266px;
  h2 {
    color: var(--v-secondary-base);
  }
  h4 {
    color: $primary !important;
    font-weight: 400;
  }
  .v-btn {
    border-radius: $border-radius-root;
    width: 341px;
    height: 41px;
    font-size: 12px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      max-width: 170px;
    }
  }
}
</style>
<script>
import { mapGetters } from "vuex";
export default {
  name: "BeCustomerCardHorizontal",
  props: {
    proposal: { type: Object, required: true }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
  }
};
</script>
